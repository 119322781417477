.project-card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  background: linear-gradient(90deg, #a993fe 0%, #7e61e7 100%);
  box-shadow: 0 16px 14px 0 #04152d;
  padding: 18px 24px;
  max-width: 345px;
}

.image-project {
  margin-bottom: 10px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  margin-top: 0; /* Tambahkan ini untuk menghilangkan margin atas bawaan */
}

.description {
  margin-top: 6px;
  font-size: 16px;
  font-weight: 250;
  margin-bottom: 6px; /* Tambahkan ini untuk memberikan sedikit ruang di bawah description */
}

.skills-item {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  list-style: none;
  padding: 0; /* Tambahkan ini untuk menghilangkan padding bawaan pada list */
}

.skill-item {
  font-size: 15px;
  font-weight: 400;
  border-radius: 100px;
  background: #0b2447;
  padding: 2px 22px;
  margin-right: 2px; /* Mengurangi margin-right agar jarak lebih dekat */
}

.links {
  width: 100%;
  margin-top: 26px;
  display: flex;
  justify-content: space-around;
}

.link {
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  color: #ffff;
  border-radius: 100px;
  background: #04152d;
  padding: 1px 22px;
}
