.edu-card {
  background: #130f2a;
  border-radius: 0.65rem;
  border: 1.5px solid #6751b9;
  padding: 1.5rem;
  margin: 0 1rem;
}

.edu-header {
  display: flex;
  align-items: center;
}

.edu-logo {
  width: 50px; /* Set the width of your logo */
  height: 50px; /* Set the height of your logo */
  margin-right: 0.5rem; /* Adjust spacing between logo and details */
  transition: transform 0.3s ease-in-out; /* Add transition for smooth scaling */
}

.edu-logo:hover {
  transform: scale(1.1); /* Scale the logo on hover */
}

.edu-details {
  flex-grow: 1;
  margin-right: 1rem; /* Menambahkan margin untuk menciptakan ruang antara detail dan "Download Sertifikat" */
}

.edu-duration {
  display: inline-block;
  font-size: 0.7rem;
  font-weight: 400;
  background: rgba(103, 81, 185, 0.5);
  border-radius: 0.3rem;
  padding: 0.4rem 0.6rem;
  margin-bottom: 1.3rem;
  margin-right: 0.2rem; /* Menambahkan margin untuk menciptakan ruang antara durasi dan "Download Sertifikat" */
}

.edu-card h6 {
  font-size: 1.05rem;
  font-weight: 500;
  margin-bottom: 0.7rem;
}

.edu-card ul li {
  list-style: none;
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
  position: relative;
}

.edu-card ul li::after {
  content: " ";
  width: 0.5rem;
  height: 0.5rem;
  background-color: #6751b9;
  border-radius: 0.5rem;
  position: absolute;
  left: -1.3rem;
  top: 0.35rem;
}

@media (max-width: 768px) {
  .edu-card {
    margin: 0;
  }

  .edu-card h6 {
    font-size: 0.95rem;
  }

  .edu-card ul li {
    font-size: 0.75rem;
  }
}
